/** @format */

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    sans-serif;
  background-color: #282c34;
  margin-left: 2%;
}

.about {
  display: flex;
  flex-direction: row;
  text-align: start;
  align-items: center;
  margin-top: 20rem;
}

.my_image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding-right: 10px;
}

.about_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  margin: 2rem 5rem 2rem 3rem;
}

.name {
  margin-bottom: 10px;
  font-style: normal;
}

.title {
  margin-bottom: 10px;
  font-family: monospace;
}

.description {
  width: 55%;
  margin-bottom: 10px;
}
